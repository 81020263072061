import styled from "styled-components";
import { blue, hover_dark_blue } from "../utils/colors";

export const Button = styled.div`
  display: flex;
  align-items: center;
  background-color: ${blue};
  border-radius: 50px;
  padding: 15px 55px;
  font-size: 24px;
  color: #ffffff;
  font-weight: 600;
  width: max-content;
  &:hover {
    background-color: ${hover_dark_blue};
    cursor: pointer;
  }
`;
