import React from "react";
import {
  Container,
  TextImageContainer,
  TextContainer,
  Image,
  Title,
  Content,
} from "../styles/extraFeatures";
import DataInOnePlace from "../assets/DataInOnePlace.svg";
import PrimaryButton from "./button";
import API from "../assets/API.svg";
import { Link } from "../styles/navItems";

const ExtraFeatures = () => {
  return (
    <Container>
      <TextImageContainer>
        <TextContainer>
          <Title>All your data in one place</Title>
          <Content>
            Cross functional teams can work off the same information without
            managing multiple folders and files. Upward takes the complexity out
            of managing your bill data, and makes it easy for you to store,
            export, and collaborate.
          </Content>
        </TextContainer>
        <Image src={DataInOnePlace} />
      </TextImageContainer>
      <a name="api">
        <TextImageContainer>
          <Image src={API} />
          <TextContainer>
            <Title>Easy to use API</Title>
            <Content>
              Easily integrate our technology into your existing applications
              and workflows. Make it easy for your users to upload and manage
              energy bill data. Get started today with our developer friendly
              API .
            </Content>
            <Link
              href="https://app.buildupward.com/apidocumentation"
              target="_blank"
            >
              <PrimaryButton text={"API Docs"} />
            </Link>
          </TextContainer>
        </TextImageContainer>
      </a>
    </Container>
  );
};

export default ExtraFeatures;
