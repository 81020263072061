import styled from "styled-components";
import { blue } from "../utils/colors";

export const Container = styled.div`
  margin-top: 200px;
  @media only screen and (max-width: 950px) {
    display: none;
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  gap: 150px;
  margin-top: 100px;
  align-items: center;
`;

export const Icon = styled.div`
  color: ${blue};
  font-size: 35px;
`;

export const Headline = styled.div`
  color: ${blue};
  font-size: 20px;
  margin: 0 auto 80px auto;
  width: max-content;
`;

export const Header = styled.div`
  font-size: 40px;
  font-weight: 600;
  margin: 0 auto 30px auto;
  width: max-content;
`;

export const Image = styled.img`
  width: 100%;
`;

export const ListContainer = styled.div`
  width: 50%;
`;

export const ImageContainer = styled.div`
  width: 50%;
  padding-right: 50px;
`;
