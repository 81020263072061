import React from "react";
import { Container, Image } from "../styles/introducing";
import { Headline, Header, SubHeader } from "../styles/common";
import Streamlined from "../assets/Streamlined.svg";

const Introducing = () => {
  return (
    <Container>
      <Headline>Introducing Upward</Headline>
      <Header>Turbocharge your data pipelines with AI</Header>
      <SubHeader>
        Upward revolutionizes energy bill processing - employing advanced AI and
        OCR technology for data extraction. Say goodbye to manual data entry and
        human errors.
      </SubHeader>
      <Image src={Streamlined} />
    </Container>
  );
};

export default Introducing;
