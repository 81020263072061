import styled from "styled-components";
import { blue } from "../utils/colors";

export const Headline = styled.div`
  color: ${blue};
  font-size: 20px;
  margin: 0 auto;
  margin-bottom: 80px;
`;

export const Header = styled.div`
  font-size: 40px;
  font-weight: 600;
  margin: 0 auto;
  margin-bottom: 30px;
`;

export const SubHeader = styled.div`
  font-size: 20px;
  margin: 0 auto;
  line-height: 24px;
  max-width: 800px;
  text-align: center;
`;
