import React from "react";
import { Container, Headline, ActionsContainer } from "../styles/contact";
import PrimaryButton from "./button";
import { Primary } from "../styles/navActions";
import { Link } from "../styles/navItems";

export const Contact = () => {
  return (
    <Container>
      <Headline>Get in touch today.</Headline>
      <ActionsContainer>
        <Link href="https://calendly.com/max-s1ha/30min" target="_blank">
          <PrimaryButton text={"Book a live demo"} />
        </Link>
        <Link href="https://app.buildupward.com/signup">
          <Primary fontSize="24px" sides="55px">
            Get started for free
          </Primary>
        </Link>
      </ActionsContainer>
    </Container>
  );
};

export default Contact;
