import styled from "styled-components";

export const Container = styled.div`
  background-image: url("/HomeFooterBackground.svg");
  background-repeat: no-repeat;
  height: 185px;
  width: 100%;
  background-size: cover;

  @media only screen and (max-width: 400px) {
    padding-left: 20px;
    padding-right: 20px;
  }

  @media only screen and (max-width: 950px) {
    display: none;
  }
`;

export const ContentConatiner = styled.div`
  padding-bottom: 30px;
  display: flex;
  align-items: end;
  height: 100%;
  box-sizing: border-box;
  justify-content: space-between;
  padding-left: 48px;
  padding-right: 48px;
`;

export const Email = styled.div`
  color: #f3f6ff;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
`;

export const Image = styled.img``;
