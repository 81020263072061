import React from "react";
import { Container, ContentConatiner, Email, Image } from "../styles/footer";
import UpwardLogoWhite from "../assets/UpwardLogoWhite.svg";

const Footer = () => {
  return (
    <Container>
      <ContentConatiner>
        <Email>support@buildupward.com</Email>
        <Image src={UpwardLogoWhite} />
      </ContentConatiner>
    </Container>
  );
};

export default Footer;
