import React from "react";
import { Container, LogoSVG } from "../styles/navBar";
import Logo from "../assets/Logo.svg";
import NavItems from "./navItems";
import NavActions from "./navActions";

const NavBar = () => {
  return (
    <Container>
      <LogoSVG src={Logo} />
      <NavItems />
      <NavActions />
    </Container>
  );
};

export default NavBar;
