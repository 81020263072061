import React, { useState } from "react";
import { Container, Item, Link } from "../styles/navItems";

const NavItems = () => {
  const [active, setActive] = useState(0);
  const onClickItem = (index) => {
    setActive(index);
  };
  return (
    <Container>
      <Link href="#home" onClick={() => onClickItem(0)}>
        <Item active={active === 0 ? true : false}>Home</Item>
      </Link>
      <Link href="#howItWorks" onClick={() => onClickItem(1)}>
        <Item active={active === 1 ? true : false}>How it works</Item>
      </Link>
      <Link href="#api" onClick={() => onClickItem(2)}>
        <Item active={active === 2 ? true : false}>API</Item>
      </Link>
    </Container>
  );
};

export default NavItems;
