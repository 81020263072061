import styled from "styled-components";
import { blue, black } from "../utils/colors";

export const Container = styled.div`
  margin-left: 50px;
`;

export const ListItem = styled.div`
  padding: ${(props) =>
    props.active ? "30px 0px 0px 0px" : "30px 0px 30px 0px"};
  border-bottom: ${(props) => (!props.active ? "1px solid #7e7e7e" : "unset")};
  ${(props) =>
    props.active &&
    `
  &:after {
    content: "";
    // This is necessary for the pseudo element to work.
    display: block;
    // This will put the pseudo element on its own line.
    margin: 0 0;
    // This will center the border.
    width: 300px;
    // Change this to whatever width you want.
    // padding-bottom: 30px;
    padding-top: 30px;
    // This creates some space between the element and the border.
    border-bottom: 4px solid ${blue};
    // This creates the border. Replace black with whatever color you want.
  }
  `};
  transition: 0.3s;
`;

export const Header = styled.div`
  display: flex;
  gap: 30px;
  align-items: center;
`;

export const Icon = styled.div``;

export const Title = styled.div`
  color: ${(props) => (props.active ? blue : black)};
  font-size: 36px;
  line-height: 54px;
  &:hover {
    color: ${blue};
    cursor: pointer;
  }
`;

export const Content = styled.div`
  font-size: 20px;
  margin-top: 30px;
  display: ${(props) => (props.active ? "flex" : "none")};
  padding-left: ${(props) => (props.iconExists ? "75px" : "0px")};
`;
