import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 200px;
  @media only screen and (max-width: 950px) {
    display: none;
  }
`;

export const Image = styled.img`
  padding: 0 200px;
  margin-top: 100px;
`;
