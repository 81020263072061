import styled from "styled-components";

export const Container = styled.div`
  background-color: #eaedff;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 50px;
  margin-top: 200px;
  padding: 100px 0px;
  @media only screen and (max-width: 950px) {
    display: none;
  }
`;

export const ListContainer = styled.div`
  width: 50%;
  padding-right: 50px;
`;

export const ImageContainer = styled.div`
  width: 50%;
  text-align: center;
`;

export const Image = styled.img`
  width: 100%;
  max-height: 350px;
  padding-left: 50px;
  max-width: 600px;
`;
